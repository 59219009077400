<template>
  <main class="main page__view">
    <teleport to="head">
      <title>{{ product.name }} Ореховоская плитка</title>
      <meta
        property="og:description"
        :content="`Купить ${product.name} в Ореховском`"
      />
      <meta property="og:title" :content="product.name" />
      <meta property="og:image" :content="product.images[0]" />
      <meta property="og:type" content="article" />
    </teleport>
    <div class="product">
      <h2 class="product__name header-text">{{ product.name }}</h2>
      <swiper
        class="product__image-slider"
        :slides-per-view="1"
        :pagination="{ clickable: true }"
        navigation
      >
        <swiper-slide v-for="(image, idx) of product.images" :key="idx">
          <img
            class="product__image"
            :src="image"
            :alt="`изображения продукта ${product.name}`"
          />
        </swiper-slide>
      </swiper>

      <div class="product__prices-and-order">
        <div class="product__prices">
          <h3 class="product__prices__header-text header-text">
            Стоимость {{ product.name }} <b>вибропресс</b>
          </h3>
          <div
            class="product__price"
            v-for="(productPrice, idx) of product.prices"
            :key="idx"
          >
            <fontAwesomeIcon class="check-icon" icon="check" />
            Цена за {{ productPrice }}руб.
          </div>
        </div>
      </div>

      <Hr class="product__hr" v-if="product.characteristics" />

      <div class="characteristics" v-if="product.characteristics">
        <h3 class="characteristics__title header-text">
          Технические характеристики
        </h3>
        <ul class="characteristics__list">
          <li
            class="characteristics__item"
            :key="idx"
            v-for="(characteristic, idx) in product.characteristics"
          >
            <fontAwesomeIcon class="check-icon" icon="check" />
            <span>{{ characteristic }}</span>
          </li>
        </ul>
      </div>
    </div>
    <Hr />
    <div class="done-works" v-if="product.doneWorks">
      <div
        class="done-work"
        @click="showLightBox(idx)"
        v-for="(doneWork, idx) of product.doneWorks"
        :key="idx"
      >
        <img
          class="done-work__image"
          alt="Наши работы по укладке тротуарной плитки"
          :src="doneWork"
        />
        <div class="done-work__overlay">
          <fontAwesomeIcon
            class="done-work__overlay__icon"
            icon="search-plus"
          />
        </div>
      </div>
    </div>

    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="lightbox.visible"
      :imgs="product.doneWorks"
      :index="lightbox.index"
      @hide="handleLightBoxHide"
      v-if="product.doneWorks"
    ></vue-easy-lightbox>
    <Hr />
    <div class="recomandation">
      <h3 class="recomandation__header-text header-text">
        РЕКОМЕНДУЕМ ПОСМОТРЕТЬ
      </h3>
      <ProductCardList :products="recomendedProducts" />
    </div>
  </main>
</template>

<script>
import Hr from '@/components/Hr.vue';
import { mapGetters } from 'vuex';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import ProductCardList from '@/components/ProductCardList.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
SwiperCore.use([Pagination, Navigation]);
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';

import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  data() {
    return {
      slider: {
        show: false,
        sliderPage: 1,
      },
      lightbox: {
        visible: false,
        index: 0, // default: 0
      },
    };
  },
  computed: {
    ...mapGetters(['getProductById', 'getProductsByCategorieAndType']),
    product() {
      const id = parseInt(this.$route.params.id);
      return this.getProductById(id);
    },
    recomendedProducts() {
      return this.getProductsByCategorieAndType(this.product.categorie, 'any')
        .filter((product) => product.id !== this.product.id)
        .slice(0, 6);
    },
  },
  methods: {
    showLightBox(index) {
      this.lightbox.index = index;
      this.lightbox.visible = true;
    },
    handleLightBoxHide() {
      this.lightbox.visible = false;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    Hr,
    ProductCardList,
    VueEasyLightbox,
  },
};
</script>

<style scoped>
.header-text {
  color: black;
}

.product {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-gap: 20px;
}

@media (max-width: 851px) {
  .product {
    grid-template-columns: 1fr;
  }
}

.product__name {
  grid-column: 1 /3;
}

.product__image-slider {
  width: 100%;
  height: 200px;
}

.product__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 851px) {
  .product__prices-and-order {
    grid-column: 1 / 3;
  }
}

.product__prices__header-text {
  font-weight: lighter;
}

/* Product Price */
.product__price {
  margin-top: 10px;
  display: flex;
  column-gap: 5px;
}

.check-icon {
  color: var(--primary-color);
  width: 1em;
  margin-right: 3px;
}

.product__order {
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  font-size: large;
  background-color: var(--primary-color);
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}

.product__hr {
  grid-column: 1 / 3;
}

@media (max-width: 851px) {
  .product__hr {
    grid-column: initial;
  }
}

.characteristics {
  grid-column: 1 / 3;
}

.characteristics__list {
  margin-top: 20px;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4px;
  justify-content: space-between;
}

@media (max-width: 730px) {
  .characteristics__list {
    grid-template-columns: 1fr;
  }
}

.characteristics__item {
  display: flex;
  align-items: center;
}

/* Works */
.done-works {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-gap: 20px;
}

@media (max-width: 880px) {
  .done-works {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 650px) {
  .done-works {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Wone Work */
.done-work {
  position: relative;
  cursor: pointer;
  height: 200px;
}

/* Done Work Image */
.done-work__image {
  width: 100%;
  height: 100%;
}
.done-work__overlay {
  display: none;
  position: absolute;

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .done-work__overlay {
    display: flex;
  }
}

.done-work:hover > .done-work__overlay {
  display: flex;
}

.done-work__overlay__icon {
  width: 35%;
}
</style>
